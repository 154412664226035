import { _js } from '@ifixit/localize';
import { Modal } from 'Shared/modal';
import { Auth } from 'Shared/auth';
import { Utils } from 'Shared/utils';
import { GlobalHeader } from 'Shared/global-header';

onDomReady(() => {
   Header.initialize();

   let expandedNavTrigger = '#menu-button';

   if (!$('searchDropdown')) {
      expandedNavTrigger += ', .search-toggle';
   }

   $$(expandedNavTrigger).addEvent('click', ev => {
      ev.stop();
      $$('.mobile-nav').toggleClass('expanded');
   });
});

// eslint-disable-next-line no-var
export var Header = (window.Header = new GlobalHeader());

Header.initialize = function () {
   this.profileMenuToggle = $('profileMenuToggle');
   this.profileMenuContainer = $('profileMenuContainer');
   this.profileMenu = $('profileMenu');

   if (window.Auth) {
      this.initLogin();
   }

   when(this.profileMenuToggle, () => {
      this.setupHeaderDropdown(this.profileMenuToggle, this.profileMenuContainer);
   });

   window.addEvent('click', ev => {
      if (
         this.searchDropdown &&
         this.searchDropdown.isVisible() &&
         ev.page.y >= this.searchDropdown.getCoordinates().bottom
      ) {
         this.hideSearchDropdown();
      }
   });

   this.searchDropdown = $('searchDropdown');
   if (this.searchDropdown) {
      this.searchDropdown.store('visible', false);
      $$('.search-toggle').addEvent('click', ev => {
         ev.stop();
         if (this.searchDropdown.retrieve('visible')) {
            this.hideSearchDropdown();
         } else {
            this.showSearchDropdown();
         }
      });
   }
};

Header.initLogin = function () {
   when($('navLogin'), a => {
      a.addEvent('click', ev => {
         let reload = true;
         if (Utils.hasUnsavedChanges()) {
            reload = false;
         }

         ev.stop();

         Auth.login({
            from: 'navLogin',
            message: _js('Logging in will reload the page.'),
            reload: reload,
            register: false,
         });
      });
   });

   when($('navSignup'), a => {
      a.addEvent('click', ev => {
         let reload = true;
         if (Utils.hasUnsavedChanges()) {
            reload = false;
         }

         ev.stop();

         Auth.login({
            from: 'navSignup',
            message: _js('Registering will reload the page.'),
            reload: true,
            register: true,
         });
      });
   });

   $$('.js-trigger-language-modal').addEvent('click', ev => {
      ev.stop();

      let options = {
         type: 'element',
         href: '#language-modal',
      };

      Modal.open(options);
   });
};

Header.showSearchDropdown = function () {
   this.searchDropdown.removeClass('search-hidden');
   this.searchDropdown.store('visible', true);
   $('mobileSearchForm').focus();
};

Header.hideSearchDropdown = function () {
   this.searchDropdown.addClass('search-hidden');
   this.searchDropdown.store('visible', false);
   $('mobileSearchForm').blur();
};
