import { Utils } from 'Shared/utils';
import { Modal } from 'Shared/modal';
import { Header } from 'Shared/header';
import { CookieUtils } from 'Shared/cookies/cookie-utils';
import trackInPiwikAndGA from 'Shared/Analytics/CombinedGAPiwik';

/**
 * @typedef {(...a: any)=>any} fn
 * @type {{
 *    required: (options) => any,
 * }}
 */
export const Auth = (window.Auth = new Class({}));

Object.append(Auth, {
   protocol: window.location.protocol.slice(0, -1),
   cookies: [],

   defaultOptions: {
      from: 'navLogin',
      onAuthorize: function () {},
      close: true,
      reload: false,
      locked: false, // locked and noOverlay are used at /Login
      noOverlay: false,
      stayInWindow: false,
      register: true,
   },

   login: function (options) {
      options = Object.merge({}, this.defaultOptions, options);

      if (this.trySSOLogin(options)) {
         return;
      }

      Modal.open({
         type: 'module',
         name: 'Login',
         boxClass: 'loginModal',
         locked: options.locked,
         noOverlay: options.noOverlay,
         onCancel: options.onCancel,
         serverOptions: {
            register: options.register,
         },
         clientOptions: {
            onAuthorize: options.onAuthorize,
            close: options.close,
            reload: options.reload,
         },
      });
   },

   logout: function () {
      window.location = window.shared_constants.GuideURI('TAG_LOGOUT');
   },

   trySSOLogin: function (options) {
      let ssoURL = App['single-sign-on-url'],
         ignoreSSO = options && options.ignoreSSO,
         cookieOptions = {
            maxAgeInSeconds: 840,
         };

      // Bail out early if we shouldn't use SSO
      if (App['multi-login'] || !ssoURL || ignoreSSO) {
         return false;
      }

      ssoURL = options.register ? ssoURL.register : ssoURL.login;

      // If we are supposed to reload the page after logging in use the
      // current window for the SSO redirecting
      if (options.reload || options.stayInWindow) {
         CookieUtils.set('sso-origin', window.location.href, cookieOptions);
         window.location = ssoURL;

         // Otherwise, do the SSO login in a new window
      } else {
         // Set the origin to a special url that temote_login checks for and
         // instead of redirecting, will just close the window.
         CookieUtils.set(
            'sso-origin',
            window.shared_constants.GuideURI('TAG_SSO_CLOSE_WINDOW'),
            cookieOptions
         );

         let loginWindow = window.open(ssoURL, 'sso_window');
         let self = this;
         Utils.onChildWindowClose(loginWindow, closed => {
            // if the window wasn't closed, then it timed out and we should
            // ignore it.
            if (!closed) {
               return;
            }

            new Request.JSON({
               url: window.shared_constants.GuideURI('TAG_USER_DETAILS'),
               onSuccess: self.responseHandler,
            }).get();
         });
      }

      return true;
   },

   clearCookies: function () {
      this.cookies.forEach(cookieName => Cookie.dispose(cookieName));
      this.cookies = [];
   },

   sendFacebookLogin: function (handler) {
      this.onComplete = handler;

      // meta and www share the same session cookie.
      let domain = window.location.toString().replace(/^[^/]+\/\/([^/]+).*$/, '$1');
      domain = domain.replace(/^meta\./, 'www.');

      new Element('script', {
         src:
            this.protocol +
            '://' +
            domain +
            window.shared_constants.GuideURI('TAG_LOGIN') +
            '/facebookLogin?' +
            Number.random(0, 999_999_999),
      }).inject(document.body);

      // If the request fails, remove the cookies.
      this.clearCookies.delay(15_000, this);
   },

   sendLogin: function (options) {
      this.onComplete = options.handler;

      let login = options.login,
         password = options.password,
         googleOAuthData = options.googleOAuthData,
         googleOAuthTokenCode = null;
      if (googleOAuthData) {
         /**
          * NOTE: Encoding the whole googleOAuthData object causes issues in
          * IE. To get around this we just encode the googleAuthToken code and
          * pass that to the server.
          */
         googleOAuthTokenCode = googleOAuthData.credential;
      }

      let self = this;
      let data = {
         login: login,
         password: password,
         googleOAuthToken: googleOAuthTokenCode,
         captchaResult: options.captchaResult,
      };

      return new Request.AjaxIO('login').send(data).then(response => {
         response.login = login;
         if (googleOAuthData) {
            response.from = 'google';
         }
         self.responseHandler(response);
         return response;
      });
   },

   sendRegister: function (formValues, handler) {
      this.onComplete = handler;

      // Set the captcha to a random value for tests.
      if (!formValues.captchaResult) {
         formValues.captchaResult = '1234';
      }

      let endpoint = 'users/join';
      if (formValues.inviteCode !== -1) {
         endpoint = 'users/join/withInviteCode';
      }
      new Request.API_2_0(endpoint, {
         method: 'POST',
         onSuccess: function (response) {
            response.loggedIn = true;
            response.newUser = true;
            response.from = 'register';
            this.responseHandler(response);
         }.bind(this),
         onFailure: function (response) {
            response = JSON.parse(response.response);
            response.loggedIn = false;
            this.responseHandler(response);
         }.bind(this),
      }).send(formValues);
   },

   required: function (options) {
      if (!this.isLoggedIn()) {
         if (options.reload) {
            options.onAuthorize = function () {};
         }
         this.login(options);
         return false;
      } else if (options.onAuthorize) {
         options.onAuthorize();
      }
      return true;
   },

   responseHandler: function (details) {
      if (details.loggedIn) {
         Auth.loggedIn(details);
      } else {
         Auth.loggedOut(details);
      }
      if (this.onComplete) {
         this.onComplete(details);
      }
   },

   loggedIn: function (info) {
      Header.showLoggedIn(info);
      App.isLoggedIn = true;

      const loginType = info.newUser ? 'Join' : 'Log In';

      const eventAction = (function () {
         switch (info.from) {
            case 'google': {
               return loginType + ' - Google Sign-in';
            }
            case 'facebook': {
               return loginType + ' - Facebook Sign-in';
            }
            case 'register': {
               return loginType + ' - Email Sign-up';
            }
            case 'email': {
               return loginType + ' - Email Sign-in';
            }
            default: {
               return null;
            }
         }
      })();
      if (eventAction) {
         trackInPiwikAndGA({
            eventCategory: loginType,
            eventAction: eventAction,
         });
      }
   },

   loggedOut: function (info) {
      Header.showLoggedOut();
      App.isLoggedIn = false;
      /**
       * Simple hack to force the user to go to the home page when logging
       * out on specific pages. The real fix would be to pass in a funcion
       * for log out, but Shawn should handle that and he's too busy.
       *
       * To add a new page add a unique identifer from the URL to the array.
       */
      let location = window.location.href;
      ['edit-profile', 'view-profile'].forEach(item => {
         if (location.includes(item)) {
            window.location = window.shared_constants.GuideURI('TAG_SITE_HOMEPAGE');
         }
      });
      if (info && info.reload) {
         window.location.reload();
      }
   },

   isLoggedIn: function () {
      return App.isLoggedIn;
   },
});

Object.append(Auth, Utils.EventsFunctions);
